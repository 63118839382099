import React from 'react'
import { graphql, Link } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'Wall Panels Installed' },
    { img: data.i2, caption: 'Insulating Metal Stud Demising Wall' },
  ];

  return (
    <Layout>
      <SEO title="System Overview: Demising Walls / Tenant Separation Walls" keywords={[]}/>
      <div className="container system-overview-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Overview</h2>
        <h1 className='title title--xxxl color-primary'>Demising Walls / Tenant Separation Walls</h1>

        <div className='grid grid--gutterless'>
          <div className='grid__column grid__column--12 grid__column--4--md'>
            <p>There are many ways to construct the demising or tenant separation walls
            between units to obtain the desired STC rating and U.L. Fire Rating.  The most
            economical and common method is to use a single 6” metal stud wall. If you need
            a 1-Hour U.L. Fire Rating, you only need one layer of drywall on each side. If
            you need a 2-Hour U.L. Rating, you’ll have two layers of drywall on each side.
            You can use either standard fiberglass insulation or Sound Batts (mineral wool).
            You can put resilient channels on one or both sides depending on the desired
            STC rating. Infinity Structures had an Independent Lab do a series of STC Tests
            on our 14 Gage Infinity Wall Panels, so we have actual STC test results on
            several different wall  assemblies. Please consult with Infinity to discuss the
            STC performance, cost/benefit, pros and cons of the various assemblies.</p>
            <p className='type--lg'>
              <Link className="link-next" to="/system-overview/roof-systems/">
                Next Page: Roof Systems
              </Link>
            </p>
            <p>
              <Link className="link-prev"  to="/system-overview/ceiling-treatments/">
                Prev Page: Ceiling Treatments
              </Link>
            </p>
          </div>

          <div className='grid__column grid__column--12 grid__column--8--md'>
            <div className='carousel-container carousel-container--padding-x'>
              <Carousel
                content={images}
                carWidth={474}
                carHeight={316}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-overview/demising-walls/gallery/01-WallP110_(40667).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i2: file(relativePath: { eq: "system-overview/demising-walls/gallery/02-WallP300_(43045).png" }) {
            ...SystemOverviewCarouselImageFragment },
    }
`
